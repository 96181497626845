import { Injectable } from '@angular/core';
import { Store } from "@ngrx/store";
import { catchError, filter, map, Observable } from "rxjs";
import { ClientOrganizationTypeEnum } from "../enums/client-organization.enum";
import { APIService, unwrapResponse } from "./api.service";
import { ErrorHandlerCreator, ResponseErrorHandler } from "./response-error-handler.service";

@Injectable({
  providedIn: 'root'
})
export class ClientOrganizationService
{
  private handleError: ErrorHandlerCreator;
  private _clientOrganization: string = '';
  constructor(private readonly api: APIService,
    private store: Store,
    private readonly responseErrorHandler: ResponseErrorHandler)
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("ClientOrganizationService");
  }

  getClientOrganization(): Observable<ClientOrganizationTypeEnum>
  {
    return this.api.get<ClientOrganizationTypeEnum>(`Permission/GetOrganization`).pipe(
      unwrapResponse(),
      catchError(this.handleError("GetOrganization"))
    );
  }

  get clientOrganization(): string
  {
    return this._clientOrganization;
  }

  set clientOrganization(value: string)
  {
    this._clientOrganization = value;
  }
}
